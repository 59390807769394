import React, { useState } from "react";
import { Link } from "gatsby";
import { Menu } from "@headlessui/react";

function MenuItemDropdown({ item, label, source, headerTransparent }) {
  const [itemOpen, setItemOpen] = useState(false);
 
  const DropdownMenuItem = ({item, type}) => {
    let itemId = null
    let title = null
    let slug = null 
  
    switch(type) {
      case "pageitem":
        itemId =  item.page.frontmatter.id
        title = item.page.frontmatter.title
        slug = item.page.frontmatter.slug
        break 
      case "servicepageitem":
        itemId =  item.service.frontmatter.id
        title = item.service.frontmatter.title
        slug = item.service.frontmatter.slug
        break 
    } 


    if(source && source =='mobilemenu') { 
      return (<Link
        to={`/${slug && slug.length < 2 ? "" : slug}`}
        className="block py-2 px-2 hover:underline" 
      >
        {item.label}
      </Link>)
    }
      return (<Menu.Item className="block p-4 font-normal hover:bg-gray-50 focus:outline-none focus:bg-gray-50  text-gray-700 hover:text-brand-600 transition-colors duration-150 ease-in">
      {({ active }) => (
        <Link to={`/${slug}/`} title={title ? title : item.label}>
         {item.label}
        </Link>
      )}
    </Menu.Item>)
  }

  if(source && source =='mobilemenu') {
    return <div className="py-4 px-8  border-b border-gray-300">
            <span className="font-semibold mb-2 block">{label}</span>
            {item.items.map((item,i)=>(
                <DropdownMenuItem key={i}  item={item} type={item.type}/>  
            ))}
      </div>
  }
  return (
    <div className={`${headerTransparent ? 'text-white hover:text-brand-200' : 'text-gray-800 hover:text-brand-600'} relative inline-block text-left font-semibold text-base transition-colors duration-150 ease-in`} data-type={item.dropdownonesection} >
      <Menu>
        <Menu.Button className={`flex items-center font-semibold py-10 border-b-4 border-transparent  focus:outline-none button-active-bhvr ${headerTransparent ? 'hover:border-brand-300' : 'hover:border-brand-600'}`}>
          {({ open }) => (
            <>
              <span>{label}</span>
              <svg
                className={`w-5 h-5 opacity-75 rotate-0  duration-75 transition-transform ease-in ${
                  open ? "transform rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </>
          )}
        </Menu.Button>

        <Menu.Items className="absolute left-0   origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none" style={{width:'20rem'}}>
            {item.items.map((item,i)=>(
                <DropdownMenuItem key={i}  item={item} type={item.type}/> 
            ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default MenuItemDropdown;
