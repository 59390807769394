import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import useSiteMetadata from "./SiteMetadata";
import brandLogos from "../settings/company_brand.json";
import AreaServedList from './Footer/AreasServedList'
import ServicesOffered from './Footer/ServicesOffered'

const TwitterIcon = (
  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
  </svg>
);
const FacebookIcon = (
  <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
      clipRule="evenodd"
    />
  </svg>
);
const FacebookSocialLink = ({ link }) => {
  return (
    <a href={link} target="_blank" className="text-gray-400 hover:text-gray-500">
      <span className="sr-only">Facebook</span>
      {FacebookIcon}
    </a>
  );
};
const GoogleIcon = (
  <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z"
    ></path>
  </svg>
);
const GoogleSocialLink = ({ link }) => {
  return (
    <a href={link} target="_blank" className="text-gray-400 hover:text-gray-500">
      <span className="sr-only">Google</span>
      {GoogleIcon}
    </a>
  );
};
const InstagramIcon = (
  <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
      clipRule="evenodd"
    ></path>
  </svg>
);
const InstagramSocialLink = ({ link }) => {
  return (
    <a href={link} target="_blank" className="text-gray-400 hover:text-gray-500">
      <span className="sr-only">Instagram</span>
      {InstagramIcon}
    </a>
  );
};
const DribbleIcon = (
  <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
      clipRule="evenodd"
    />
  </svg>
);
const DribbleSocialLink = ({ link }) => {
  return (
    <a href={link} target="_blank" className="text-gray-400 hover:text-gray-500">
      <span className="sr-only">Dribbble</span>
      {DribbleIcon}
    </a>
  );
};

function Footer({isHomePage = false}) {
  const {
    companyname,
    companynamefull,
    license,
    altslogan,
    email,
    phonenumber,
    reviewavg,
    reviewscount,
    facebookurl,
    instagramurl,
    googleurl,
    yelpurl,
    guildqualityurl,
    addressLine1,
          city,state,zip
  } = useSiteMetadata();

  return (
    <StaticQuery
      query={graphql`
        query getCities {
          allServiceAreaContent: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "service-area" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  featuredcity
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { allServiceAreaContent } = data;
        let allServiceAreaContentClean = [...new Map(allServiceAreaContent.edges.map(item => [item.node.id, item])).values()]

        return (
    <div className="bg-white py-12">
      <div className=" px-4 md:px-6 py-4 lg:py-0 container mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:gap-12">
          <div>
            <Link to="/" className="flex" title={companyname}>
              <div
                style={{ width: "210px" }}
                className="font-bold text-lg font-display mb-2"
              >
                {brandLogos.companylogo ? (
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{
                      __html: brandLogos.companylogo,
                    }}
                  ></div>
                ) : brandLogos.companylogodark ? (
                  <img src={brandLogos.companylogodark} className="w-full" />
                ) : (
                  companyname
                )}

              </div>
            </Link>
            {license ?  <span className="block py-2"><span className="block text-gray-800 font-semibold">Licensed &amp; Insured</span> <span className="block text-gray-600">{license}</span></span> : null}

              <span className="block py-2">

                <span className="block text-gray-800 font-semibold">Contact Us</span>
                <span className="block text-gray-600">{phonenumber}</span>
                <span className="block text-gray-600"><a href={`mailto:${email}`} className="block">{email}</a></span>

                <span className="block text-gray-600 mt-2">
                 {addressLine1 ? (
                     <>
                     {addressLine1} <br/>
                     </> ) : null}

                  {city ? (
                      <>
                        {city}, {state} {zip}
                      </> ) : null}

                </span>


                </span>


            <div className="mt-8   space-x-4 hidden">
              {instagramurl ? <div>insta</div> : null}
              {yelpurl ? <div>yelpurl</div> : null}
              {facebookurl ? <div>facebookurl</div> : null}
              {guildqualityurl ? <div>guildqualityurl</div> : null}
              {googleurl ? <div>googleurl</div> : null}
            </div>
          </div>
          <div>
            <div className="md:mt-0 mt-6">
              <h4 className="text-sm leading-5 font-bold tracking-wider text-brand-600 uppercase font-display">
                Why Us
              </h4>
              <ul className="mt-2 leading-relaxed p-0  list-none space-y-2">
                <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="text-brand-500 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              No appraisal or home inspection.

                </li>
                <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="text-brand-500 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              No repairs needed, as-is sale.

                </li>
                <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="text-brand-500 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              No fees, taxes, or commissions 

                </li>
                 
                <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-brand-500 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  Just one walkthrough.

                </li>
                <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-brand-500 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  Close date of YOUR choice.
                </li>






              </ul>
            </div>
          </div>
          <ServicesOffered/>
           <AreaServedList/>

        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base leading-6 text-gray-700 md:text-center max-w-3xl mx-auto">
            &copy; {new Date().getFullYear()} {companynamefull}. All Rights
            Reserved.
            <span className="text-gray-500 text-sm block">
              <span>We are a real estate solutions and investment firm that specializes in helping homeowners get rid of burdensome houses fast. We are investors and problem solvers who can buy your house fast with a fair all cash offer. </span><br/>
              <span
                className="block md:inline my-2"
                itemScope=""
                itemType="http://schema.org/Product"
              >
                <span itemProp="name">{companyname}</span>
                <span
                  itemProp="aggregateRating"
                  itemScope=""
                  itemType="http://schema.org/AggregateRating"
                >
                  {" "}
                  rated <span itemProp="ratingValue">{reviewavg}</span> / 5
                  based on <span itemProp="reviewCount">{reviewscount}</span>{" "}
                  reviews.
                </span>
              </span>    {isHomePage ? (
              <>
              
                <span>
                  {' '}
                  Web Design &amp; Marketing by{' '}
                  <a
                    target="_blank"
                    href="https://www.webperfex.com/?utm_source=syhfcsac&utm_medium=referral"
                    style={{ color: 'rgb(136, 136, 136)' }}
                  >
                    WebPerfex
                  </a>
                </span>
              </>
            ) : null}
            </span>
          </p>
         
        </div>
        <div className="mt-4 border-t border-gray-200 pt-4">

              <span className="text-gray-500 text-xs leading-5 block mx-auto max-w-4xl md:text-center">Proudly serving
              {allServiceAreaContentClean.map((item, i) => (
                <span>
                  {i != 0 ? ", " : " "}
                  <Link
                  className="hover:text-brand-600"
                    to={`/sell-my-house-fast-in-${item.node.frontmatter.slug}/`}
                    title={`Sell Your House Fast In ${item.node.frontmatter.title}`}
                  >{item.node.frontmatter.title}
                  </Link>
                </span>
              ))}  and surrounding areas.</span>

</div>
      </div>
   
    </div>
  );
}}
/>
);
}
export default Footer;


